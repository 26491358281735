import { graphql } from 'gatsby';
import React, {useEffect} from 'react';
import { matchPath } from "react-router";
import App from '../components/App/App';
import { gql, useQuery } from '@apollo/client';
import SEO from "../components/seo"

//TODO: Query to GQL should only request ID for the given make/camera/lens
console.log('THIS IS 404.js')

// Get context variables (gears names) from URL path
var queryFromURL = {make: {}, camera: {}, lens: {}}

if (typeof window !== `undefined`) {
    function decodeUrlParam(value) {
        if (value == null || (typeof value !== 'string' && !(value instanceof String))) { return value }
        console.log('404.js decodeUrlParam:'+JSON.stringify(value))
        return decodeURIComponent(value.replace(/_/g,' ')).replace(/⁄/g,'/');
    }
    let location = window.location;
    console.log(`location is: ${location}`)
    console.log(`location.pathname is: ${location.pathname}`)

    const match = matchPath(location.pathname, {  // window.location.pathname
        path: "/search/:make([^/]+)?/:camera([^/]+)?/:lens([^/]+)?",
        exact: true,
        strict: false
    });

    if (match) {
        console.log('404.js match1:'+JSON.stringify(match.params))
        for (const key in match.params) {
            queryFromURL[key] = decodeUrlParam(match.params[key])
        }
        console.log('404.js queryFromURL:'+JSON.stringify(queryFromURL))
    } else {
        console.log(`404.js match NOT found in URL ... to fix`)
    }
}

const NotFoundPage = ({ data: { gv }, pageContext }) => {
    console.log('404.js NotFoundPage starting ...')
    // Get context variables (gears ids) from gears name via GQL
    // const [queryFromURLWithId, setQueryFromURLWithId] = useState({make:{},camera:{},lens:{}})
    var queryFromURLWithId = {make:null,camera:null,lens:null}
    const GQL_GEARS = gql`query getGears{ makes { _id Make } cameras { _id CameraModel } lenses { _id LensModel }}`;
    const { loading, error, data } = useQuery(GQL_GEARS);
    if (loading) {
        console.log(`404.js gql querying ...`)
    } else {
        // console.log(`404.js gql querying done: ${JSON.stringify(data)}`)
        var gears = {make:null,camera:null,lens:null}
        if (queryFromURL.make) {
            const m = data.makes.find(el => el.Make === queryFromURL.make);
            gears.make = m ? m : null
            console.log(`404.js make is: ${JSON.stringify(m)}`)
        }
        if (queryFromURL.camera) {
            const c = data.cameras.find(el => el.CameraModel === queryFromURL.camera);
            gears.camera = c ? c : null
            console.log(`404.js camera is: ${JSON.stringify(c)}`)
        }
        if (queryFromURL.lens) {
            const l = data.lenses.find(el => el.LensModel === queryFromURL.lens);
            gears.lens = l ? l : null
            console.log(`404.js lens is: ${JSON.stringify(l)}`)
        }
        // setQueryFromURLWithId(gears)
        queryFromURLWithId = gears
    }
    // This will first render with queryFromURLWithId.* null and then again once GQL returns with queryFromURLWithId.* not null
    // previously <ApolloProvider client={client}> instead of <>
    return (
        <>
            <SEO context={pageContext} />
            <App btc={gv} context={{...pageContext, origin: '404', makerSelected: queryFromURLWithId.make, cameraSelected: queryFromURLWithId.camera, lensSelected: queryFromURLWithId.lens}} />
        </>
    )
}
export default NotFoundPage

// Not including cameras and lenses in GQL to reduce payload and because URL parse will trigger GQL during browser runtime.
export const query = graphql`
  query {
    gv {
      makes { _id Make }
    }
  }
`
